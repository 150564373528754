const state = {
  stripePersons: []
}

const getters = {
  stripePersons: state => state.stripePersons
}

const actions = {
  setStripePersons: ({
    commit
  }, stripePersons) => {
    commit('setStripePersons', stripePersons)
  }
}

const mutations = {
  setStripePersons(state, stripePersons) {
    state.stripePersons = stripePersons
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}