function orderDelivStatus(status) {
  if (typeof (status) !== 'number') return ''
  switch (status) {
    case 0:
      return 'Created'
    case 1:
      return 'Driver Requested'
    case 2:
      return 'Driver Assigned'
    case 3:
      return 'Picked up'
    case 4:
      return 'Delivered'
    default:
      return ''
  }
}

export default orderDelivStatus