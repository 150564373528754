import _ from 'underscore'

function modifierChoices(modifier) {
  if (modifier) {
    const choices = _.map(modifier.choices, c => {
      if (c.price > 0) return c.name + " $" + c.price;
      else return c.name;
    });
    return choices.join(", ");
  }
  return "";
}


export default modifierChoices