<template lang="pug">
v-row(v-if='balance')
  v-col(align='center')
    .caption.sl-secondary-text Available Fund
    .subtitle-2(v-for='(item, index) in balance.available' :key='index') {{ item.amount/100 | currency }}
  v-col(align='center')
    .caption.sl-secondary-text Pending Fund
    .subtitle-2(v-for='(item, index) in balance.pending' :key='index') {{ item.amount/100 | currency }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      balance: null
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"])
  },
  methods: {
    load() {
      if (!this.stripeAccount) return;
      const options = {
        accountId: this.stripeAccount.id
      };
      this.axios
        .post("/stripe/balance/retrieve", options)
        .then(response => (this.balance = response.data));
    }
  },
  mounted() {
    this.load();
  }
};
</script>
