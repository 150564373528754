const state = {
  stripeBanks: []
}

const getters = {
  stripeBanks: state => state.stripeBanks
}

const actions = {
  setStripeBanks: ({
    commit
  }, stripeBanks) => {
    commit('setStripeBanks', stripeBanks)
  }
}

const mutations = {
  setStripeBanks(state, stripeBanks) {
    state.stripeBanks = stripeBanks
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}