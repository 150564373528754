<template lang="pug">
v-simple-table(dense, v-if="payouts")
  thead
    tr
      th Created
      th Arrive Date
      th Amount
      th.text-right Status
  tbody
    tr(v-for="(item, index) in payouts", :key="index")
      td {{ item.created * 1000 | date }}
      td {{ item.arrival_date * 1000 | date }}
      td {{ item.amount / 100 | currency }}
      td.text-capitalize.text-right {{ item.status }}
    tr
      td Total
      td 
      td {{ total | currency }}
      td.text-right {{ payouts.length }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: ["year"],
  data() {
    return {
      payouts: [],
    };
  },
  computed: {
    ...mapGetters(["stripeAccount"]),
    total() {
      return _.reduce(
        this.payouts,
        (memo, item) => {
          return memo + item.amount / 100;
        },
        0
      );
    },
  },
  watch: {
    year() {
      this.load();
    },
  },
  methods: {
    async load() {
      if (!this.stripeAccount || !this.year) return;
      const options = {
        destination: this.stripeAccount.id,
        year: this.year,
      };
      const res = await this.axios.post("/stripe/payouts/list", options);
      this.payouts = res.data;
    },
  },
};
</script>
