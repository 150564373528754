import _ from "underscore";

const state = {
  driverPayouts: [],
};

const getters = {
  driverPayouts: state => state.driverPayouts,
};

const actions = {
  setDriverPayouts: ({
    commit
  }, data) => {
    commit("setDriverPayouts", data);
  },
  updateDriverPayout: ({
    commit
  }, data) => {
    commit("updateDriverPayout", data);
  }
};

const mutations = {
  setDriverPayouts(state, data) {
    state.driverPayouts = data;
  },
  updateDriverPayout(state, data) {
    state.driverPayouts = _.map(state.driverPayouts, o => {
      if (o._id === data._id) return data;
      return o;
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};