function minutesToTime(minutes) {
  if (isNaN(minutes)) return 'N/A'
  minutes = Math.round(minutes) // ensure integer
  var hh = Math.floor(minutes / 60)
  var ampm = 'AM'
  if (hh >= 12) {
    ampm = 'PM'
    hh -= 12
  }
  if (hh === 0) hh = 12
  hh = String(hh)
  var mm = String(minutes % 60)
  if (mm === '0') return hh + ' ' + ampm
  else {
    if (mm.length === 1) mm = '0' + mm
    return hh + ':' + mm + ' ' + ampm
  }
}

export default minutesToTime