import moment from 'moment'

/**
 * @param {number/string} input: unix timestamp or other standard time
 * @param {*} format: optional. Allow customize output format
 */
function datetime(input, format) {
  if (format) return moment(input).format(format)
  else return moment(input).format('M/D/YYYY h:mm A')
}

export default datetime