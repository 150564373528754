<template lang="pug">
v-card.my-3
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      .caption.sl-secondary-text Bank Accounts
      CreateBank
    v-divider.mt-2
  List
</template>

<script>
import List from "./List";
import CreateBank from "./CreateBank";

export default {
  components: { List, CreateBank }
};
</script>
