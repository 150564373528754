<template lang="pug">
v-menu(open-on-hover, offset-y)
  template(v-slot:activator="{ on }")
    v-btn(outlined, v-on="on") {{ selected }}
      v-icon(right) mdi-menu-down
  v-list.py-0(dense)
    v-list-item(
      v-for="(item, index) in years",
      :key="index",
      @click="select(item)"
    )
      v-list-item-content
        v-list-item-title {{ item }}
</template>

<script>
import _ from "underscore";

export default {
  name: "YearSelector",
  data() {
    return {
      selected: new Date().getFullYear(),
      years: _.range(new Date().getFullYear(), 2015),
    };
  },
  methods: {
    select(item) {
      this.selected = item;
      this.$emit("select", this.selected);
    },
  },
  mounted() {
    // send in initiating
    this.$emit("select", this.selected);
  },
};
</script>
