<template lang="pug">
div
  span Agree to 
    a(@click.stop='dialog = !dialog') term of use
  v-dialog(v-model='dialog' max-width='700px')
    v-card
      v-card-text.py-3
        include ./stripeToS
</template>

<script>
export default {
  name: "StripeToS",
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    open() {
      this.dialog = true;
    }
  }
};
</script>